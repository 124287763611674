import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Hidden, Paper, Tabs, Tab } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'

import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import InfoCard from '../molecules/InfoCardLanding'
import InfoCardTexts from '../../static/files/InfoCardTexts'

const { planPerksOnline, planPerksPrivate, planPerksCorpo, planPerksDigital } =
  InfoCardTexts

const PlansCard = ({ purpleFriday }) => {
  const theme = useTheme()

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: '11%',
    },
    componentBody: {
      background: theme.palette.background.main,
      borderRadius: '50px 50px 0px 0px',
    },
    businessTitle: {
      margin: '79px 0 55px',
      '& .MuiTypography-h3': {
        color: theme.palette.text.primary,
      },
    },
    tabsHolder: {
      marginBottom: '55px',

      '& .MuiTab-root': {
        width: '230px',
        borderBottom: `2px solid ${theme.palette.action.disabledBackground}`,
        color: theme.palette.primary.soft,

        '@media(max-width: 460px)': {
          width: '150px',
        },
      },

      '& .MuiTab-wrapper': {},

      '& .Mui-selected': {
        color: theme.palette.primary.bgLight,
      },

      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.bgLight,
      },
    },
    cardsHolder: {
      marginBottom: '85px',
      '& .MuiGrid-item': {
        display: 'flex',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'unset',
      },
    },
    infoCardsHolder: {
      display: 'flex',
      margin: '0 auto',
    },
  }))

  const classes = useStyles()
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const changeIndex = index => {
    setValue(index)
  }

  const InfoCardUse = tabValue => (
    <Grid container direction="row" justify="center" alignItems="center">
      <Hidden mdDown>
        <Grid item xs={12}>
          <div className={classes.infoCardsHolder}>
            <InfoCard
              planName="Online"
              type={tabValue === 1 ? 'comercio' : 'servico'}
              oldPrice="12900"
              newPrice="8900"
              planDescription="A opção mais econômica para sua empresa."
              planPerks={planPerksOnline}
              disablePrice
              noPrice
              purpleFriday={purpleFriday}
            />
            <InfoCard
              planName="Digital"
              previousPlan="Online"
              type={tabValue === 1 ? 'comercio' : 'servico'}
              oldPrice="59900"
              newPrice="40000"
              planDescription="A opção mais econômica para sua empresa, com certificado digital."
              planPerks={planPerksDigital}
              bestChoiceStyle
              disablePrice
              noPrice
              purpleFriday={purpleFriday}
            />
            <InfoCard
              planName="Private"
              previousPlan="Online"
              type={tabValue === 1 ? 'comercio' : 'servico'}
              oldPrice="59900"
              newPrice="40000"
              planDescription="O melhor custo-benefício quando se trata de começar seu negócio."
              planPerks={planPerksPrivate}
              disablePrice
              noPrice
              purpleFriday={purpleFriday}
            />
            <InfoCard
              planName="Corporate"
              previousPlan="Digital"
              type={tabValue === 1 ? 'comercio' : 'servico'}
              oldPrice="125000"
              newPrice="80000"
              planDescription="Conforto e atendimento especializado para você e sua empresa."
              planPerks={planPerksCorpo}
              bestChoiseStyle={false}
              disablePrice
              noPrice
              purpleFriday={purpleFriday}
            />
          </div>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid item xs={12} justify="center" style={{ marginBottom: '15px' }}>
          <InfoCard
            planName="Online"
            type={tabValue === 1 ? 'comercio' : 'servico'}
            oldPrice="12900"
            newPrice="8900"
            planDescription="A opção mais econômica para sua empresa."
            planPerks={planPerksOnline}
            disablePrice
            noPrice
          />
        </Grid>
        <Grid item xs={12} justify="center" style={{ marginBottom: '15px' }}>
          <InfoCard
            planName="Digital"
            previousPlan="Online"
            type={tabValue === 1 ? 'comercio' : 'servico'}
            oldPrice="59900"
            newPrice="40000"
            planDescription="A opção mais econômica para sua empresa, com certificado digital."
            planPerks={planPerksDigital}
            bestChoiceStyle
            disablePrice
            noPrice
            purpleFriday={purpleFriday}
          />
        </Grid>
        <Grid item xs={12} justify="center" style={{ marginBottom: '15px' }}>
          <InfoCard
            planName="Private"
            previousPlan="Digital"
            type={tabValue === 1 ? 'comercio' : 'servico'}
            oldPrice="59900"
            newPrice="40000"
            planDescription="O melhor custo-benefício quando se trata de começar seu negócio."
            planPerks={planPerksPrivate}
            disablePrice
            noPrice
          />
        </Grid>
        {!tabValue && (
          <InfoCard
            planName="Corporate"
            previousPlan="Private"
            type={tabValue === 1 ? 'comercio' : 'servico'}
            oldPrice="125000"
            newPrice="80000"
            planDescription="Conforto e atendimento especializado para você e sua empresa."
            planPerks={planPerksCorpo}
            disablePrice
            noPrice
          />
        )}
      </Hidden>
    </Grid>
  )

  return (
    <div className={classes.root} id="plans-card">
      <Paper elevation={0} className={classes.componentBody}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={10} md={12} className={classes.businessTitle}>
            <Typography variant="h3" component="h2" align="center">
              Escolha a melhor opção para o seu negócio
            </Typography>
          </Grid>
          {/* <Grid item xs={12} className={classes.tabsHolder}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor={theme.palette.text.primary}
              centered
              className={classes.tabs}
            >
              <Tab label="Empresas de serviços" />
              <Tab label="Empresas de comércio" />
            </Tabs>
          </Grid> */}
          <Grid item xs={12} className={classes.cardsHolder}>
            <div value={value} index={0}>
              {InfoCardUse()}
            </div>
            {/* <SwipeableViews index={value} onChangeIndex={changeIndex}>
              <div value={value} index={0}>
                {InfoCardUse()}
              </div>
              <div value={value} index={1}>
                <Grid container>
                  <Grid item xs={12}>
                    {InfoCardUse(value)}
                  </Grid>
                </Grid>
              </div>
            </SwipeableViews> */}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

PlansCard.propTypes = {
  purpleFriday: PropTypes.bool,
}

PlansCard.defaultProps = {
  purpleFriday: false,
}

export default PlansCard
